import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    orderStatusChangeDialog: {
        open: false,
    },
    orderStatusHistoryDialog: {
        open: false,
        portionInfo: undefined,
    },
    orderCommentEditDialog: {
        open: false,
        orderId: 0,
        comment: undefined,
    },
    orderFilterDrawer: {
        open: false,
    },
    pageSize: 50,
    searchTerm: '',
    orderFilter: {
        // Must be undefined since filter is taken from localStorage and has it's own initial values.
        // Otherwise duplicated api request occurs.
        startDate: undefined,
        endDate: undefined,
        startPrice: 0,
        endPrice: 0,
        startQty: 0,
        endQty: 0,
        statuses: [],
        customers: [],
        suppliers: [],
        customerManagers: [],
        supplierManagers: [],
        logos: [],
        orders: [],
        brands: [],
        articles: [],
    },
};
export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        openOrderStatusChangeDialog: (state, action) => {
            state.orderStatusChangeDialog.open = true;
        },
        closeOrderStatusChangeDialog: (state) => {
            state.orderStatusChangeDialog.open = false;
        },
        openOrderStatusHistoryDialog: (state, action) => {
            state.orderStatusHistoryDialog.open = true;
            state.orderStatusHistoryDialog.portionInfo = action.payload;
        },
        updateOrderStatus: (state, action) => {
            if (!state.orderStatusHistoryDialog.portionInfo)
                return;
            state.orderStatusHistoryDialog.portionInfo.stateId = action.payload;
        },
        closeOrderStatusHistoryDialog: (state) => {
            state.orderStatusHistoryDialog.open = false;
            state.orderStatusHistoryDialog.portionInfo = undefined;
        },
        openOrderCommentEditDialog: (state, action) => {
            state.orderCommentEditDialog = { ...action.payload, open: true };
        },
        closeOrderCommentEditDialog: (state) => {
            state.orderCommentEditDialog.open = false;
            state.orderCommentEditDialog.orderId = 0;
            state.orderCommentEditDialog.comment = undefined;
        },
        openOrderFilterDrawer: (state) => {
            state.orderFilterDrawer.open = true;
        },
        closeOrderFilterDrawer: (state) => {
            state.orderFilterDrawer.open = false;
        },
        setOrderFilter: (state, action) => {
            state.orderFilter = action.payload;
        },
        clearOrderFilters: (state) => {
            state.orderFilter = initialState.orderFilter;
        },
        // undoResetFilters: (state, action: { payload: IStateOrderFilter }) => {
        // 	state.orderFilter = action.payload;
        // },
        // removeBackupResetFilters: (state, action: { payload: IStateOrderFilter }) => {
        // 	state.orderFilter = action.payload;
        // },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
});
export const { actions: orderActions } = orderSlice;
export const orderReducer = orderSlice.reducer;
