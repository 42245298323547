import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    searchTerm: '',
    customerAddDialog: {
        open: false,
    },
    customerEditDialog: {
        open: false,
        customerId: undefined,
        activeTab: 'general',
    },
    customerBalanceDialog: {
        open: false,
        customerId: undefined,
        customerName: undefined,
        orderId: undefined,
    },
    deliveryAddressEditDialog: {
        open: false,
        deliveryAddress: undefined,
    },
    legalEditDialog: {
        open: false,
        legal: undefined,
    },
    emailEditDialog: {
        open: false,
        customerEmail: undefined,
    },
    notificationScheduleEditDialog: {
        open: false,
        customerId: undefined,
    },
    priceListMarkupEditPopover: {
        open: false,
        markupEditData: {
            top: 0,
            left: 0,
            logo: '',
            customerId: 0,
            priceListId: 0,
            markup: undefined,
        },
    },
};
export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        openAddCustomerDialog: (state) => {
            state.customerAddDialog.open = true;
        },
        closeAddCustomerDialog: (state) => {
            state.customerAddDialog.open = false;
        },
        openCustomerEditDialog: (state, action) => {
            state.customerEditDialog.open = true;
            state.customerEditDialog.customerId = action.payload.customerId;
        },
        closeCustomerEditDialog: (state) => {
            state.customerEditDialog = initialState.customerEditDialog;
        },
        setCustomerEditDialogActiveTab: (state, action) => {
            state.customerEditDialog.activeTab = action.payload;
        },
        openCustomerBalanceDialog: (state, action) => {
            state.customerBalanceDialog.open = true;
            state.customerBalanceDialog = { ...action.payload, open: true };
        },
        closeCustomerBalanceDialog: (state) => {
            state.customerBalanceDialog.open = false;
            state.customerBalanceDialog.customerId = undefined;
            state.customerBalanceDialog.customerName = undefined;
            state.customerBalanceDialog.orderId = undefined;
        },
        openDeliveryAddressEditDialog: (state, action) => {
            state.deliveryAddressEditDialog.open = true;
            state.deliveryAddressEditDialog.deliveryAddress = action.payload.deliveryAddress;
        },
        closeDeliveryAddressEditDialog: (state) => {
            state.deliveryAddressEditDialog.open = false;
            state.deliveryAddressEditDialog.deliveryAddress = undefined;
        },
        openLegalEditDialog: (state, action) => {
            state.legalEditDialog.open = true;
            state.legalEditDialog.legal = action.payload.legal;
        },
        closeLegalEditDialog: (state) => {
            state.legalEditDialog.open = false;
            state.legalEditDialog.legal = undefined;
        },
        openEmailEditDialog: (state, action) => {
            state.emailEditDialog.open = true;
            state.emailEditDialog.customerEmail = action.payload.customerEmail;
        },
        closeEmailEditDialog: (state) => {
            state.emailEditDialog.open = false;
            state.emailEditDialog.customerEmail = undefined;
        },
        openNotificationScheduleEditDialog: (state) => {
            state.notificationScheduleEditDialog.open = true;
            state.notificationScheduleEditDialog.customerId = state.customerEditDialog.customerId;
        },
        closeNotificationScheduleEditDialog: (state) => {
            state.notificationScheduleEditDialog = initialState.notificationScheduleEditDialog;
        },
        editPriceListMarkupOpen: (state, action) => {
            state.priceListMarkupEditPopover.open = true;
            state.priceListMarkupEditPopover.markupEditData = action.payload;
        },
        editPriceListMarkupClose: (state) => {
            state.priceListMarkupEditPopover.open = false;
            state.priceListMarkupEditPopover.markupEditData = {
                top: 0,
                left: 0,
                logo: '',
                customerId: 0,
                priceListId: 0,
                markup: undefined,
            };
        },
    },
});
export const { actions: customerActions, reducer: customerReducer } = customerSlice;
