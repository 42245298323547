import { Table, styled } from '@mui/material';
// TODO: This styles should be moved to Table styles, and the component should be removed. After all catalyst refactoring is done.
export const TableCatalyst = styled(Table)(({ theme }) => ({
    '.MuiTableCell-root': {
        color: theme.palette.text.muted,
        padding: '.25rem 0.875rem',
        ':first-of-type': {
            // paddingLeft: '.25rem',
            paddingLeft: '.5rem',
        },
        ':last-of-type': {
            // paddingRight: '.25rem',
            paddingRight: '.5rem',
        },
    },
}));
