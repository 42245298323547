import { TableHead, styled, tableCellClasses, typographyClasses } from '@mui/material';
// TODO: This styles should be moved to Table styles, and the component should be removed. After all catalyst refactoring is done.
export const TableHeadCatalyst = styled(TableHead)(({ theme }) => ({
    [`.${tableCellClasses.head}`]: {
        backgroundColor: 'white',
        [`.${typographyClasses.root}`]: {
            color: `${theme.palette.zinc[500]} !important`,
        },
    },
}));
